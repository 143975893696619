/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    em: "em",
    blockquote: "blockquote",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Participatory VC 🧑‍🤝‍🧑"), "\n", React.createElement(_components.p, null, "In 2016, I first got a glimpse of what the future of venture finance could look like, when a great friend invited me to help establish his concept of a ", React.createElement(_components.a, {
    href: "https://siliconballs.com"
  }, "\"participatory\" seed VC"), ". 19 of us invested our own money with an equal stake, voting on investments and riding the ups and downs together. Later, with ", React.createElement(_components.a, {
    href: "https://hitchhiker.vc"
  }, "Hitchhiker"), ", I’ve participated in the same model at a larger scale: professionals from all walks of life, and from all around the world, deploying our pooled resources and sharing the journey."), "\n", React.createElement(_components.h2, null, "Networks 🔗"), "\n", React.createElement(_components.p, null, "I’m grateful for these experiences, because they've helped to teach me the true power of networks. We have an ambitious goal at ", React.createElement(_components.a, {
    href: "https://impulse.training"
  }, "impulse"), ", and the way that we’re making it happen is through a system called ", React.createElement(_components.em, null, "Impulse Collective"), ". Through this system, we're bringing together up to 10 experts in software, hardware, design, data engineering, and psychology, to work with our core team for 1—10 hours per week - in exchange for equity. We love knowing that at seed, members of the ", React.createElement(_components.em, null, "Impulse Collective Founding Team"), " will have earned a shareholding that rewards them for their individual contribution. Also, we can already appreciate how that motivates and aligns the network - for everyone's benefit (including our customers'!)"), "\n", React.createElement(_components.h2, null, "The question of terms for investors 📜"), "\n", React.createElement(_components.p, null, "But in preparing terms for our pre-seed investment round, we asked ourselves:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "given that there is no way to establish a valuation at our early stage, and no single discount that could fairly compensate pre-seed investors for the risks they are taking and the value that they provide, how can we genuinely and meaningfully reward our earliest investors?"), "\n"), "\n", React.createElement(_components.h2, null, "MFN SAFE terms: No Cap, No Discount"), "\n", React.createElement(_components.p, null, "At this point we met ", React.createElement(_components.a, {
    href: "https://fl0wstate.com/robert/"
  }, "Robert Lee"), ", a veteran of venture finance here in San Francisco. Rob is committed to building genuine, lasting human relationships over transactional ones, and his writing on the MFN (Most Favoured Nation) SAFE helped us uncover our answer. MFN pre-seed SAFE terms can be thought of rough as “no worse than seed”, and Rob has described these terms as sometimes being \"for investors who don't care\" (although they have become more well-known after also being incorporated into ", React.createElement(_components.a, {
    href: "https://www.ycombinator.com/deal"
  }, "YC's latest finance terms"), ")."), "\n", React.createElement(_components.h2, null, "Thankyou SAFE (or, \"Zero-Sum is Zero-Fun\") terms 🤗"), "\n", React.createElement(_components.p, null, "Here’s a question: if you’ve run an endurance race with friends, would you prefer to share your hugs and high fives at the starting line... Or after you’ve all made it? We call the terms that we are offering our pre-seed investors \"Thankyou SAFE\" terms, and they involve a \"minimum\" Discount, that can be increased, according to a \"hidden\" formula. For taking the pre-seed venture capital risk, we want to say a ", React.createElement("b", null, "big"), ": thank you! But we also want to say thank you for recommending high-performing people for Impulse Collective, or for being able to help in other ways. The reason why we can't arrive at our \"thankyou\" number now is because we don't know what that shared journey will look like with each of our investor partners. If MFN terms can be thought of as being \"for investors who don't care\", then we like to think of our terms as being \"for founders who want to say thank you\"."), "\n", React.createElement(_components.h2, null, "Coming for the ride 🚐"), "\n", React.createElement(_components.p, null, "Through Hitchhiker, I’ve been fortunate to see what “coming along for the ride” can really feel like. The monthly investor update from ", React.createElement(_components.a, {
    href: "https://www.beazy.co"
  }, "Beazy"), " is one that I treasure: a look into the successes, difficulties and stories on the inside, as well as invitations to participate in their flourishing community. That's value that goes beyond the monetary."), "\n", React.createElement(_components.h2, null, "\"Who here can change a tire?\" 👩‍🔧"), "\n", React.createElement(_components.p, null, "As founders, you're also better equipped to get through your difficult moments when you really know who's with you in the van. Get to know your network, and don’t be afraid to (respectfully!) ask for help - if and when you need it."), "\n", React.createElement(_components.h2, null, "The problem of shut doors 🚪"), "\n", React.createElement(_components.p, null, "VCs will all know Marc Andreeson's quote:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "if a founder can’t navigate a network into a VC firm, it is unlikely that founder has the skills to navigate the other networks required to succeed in building a company."), "\n"), "\n", React.createElement(_components.p, null, "But here's the question - isn't keeping doors shut in the face of cold calling founders a little ", React.createElement(_components.a, {
    href: "https://medium.com/@DelJohnsonVC/ban-warm-introductions-1e69169d57ba"
  }, "antithetical to the nature of venture"), "? It seems to me that ", React.createElement(_components.a, {
    href: "https://steveblank.com/2010/02/25/customer-development-for-web-startups/"
  }, "Customer Development"), " skills generally matter more than social engineering skills - especially at the early stage - so if you're an early-stage VC, shutting your doors might keep out capable, customer-aligned founders who simply haven't focused on the games."), "\n", React.createElement(_components.h2, null, "VCs: leave them open - a bit 🚪🔓"), "\n", React.createElement(_components.p, null, "I think the best VCs already know that by taking a look at everything that comes their way, they're not missing chances to find a hidden gem. Also, by always being kind, they're maintaining their reputation as an excellent potential partner. That said, you can't maximise your effectiveness if you have the same amount of time for everyone. Your attention is precious - so manage it."), "\n", React.createElement(_components.h2, null, "Founders: Make them want to open it 🚪🤓"), "\n", React.createElement(_components.p, null, "As a founder, it's still better to understand the dynamics of networks than to not, but I believe that regardless, the best way to \"be so good they can't ignore you\" (also a quote from Marc) is to focus on delighting your customers."), "\n", React.createElement(_components.h2, null, "Premature money is worse than no money 💸"), "\n", React.createElement(_components.p, null, "If you raise money too early, you'll probably spend it - and then you'll run out. If you're just getting started, focus on the learning instead, and on demonstrating your ideas on the smallest-possible scale first. Expect to be wrong - you always are when you start! When you're ready, the investors will be, too."), "\n", React.createElement(_components.h2, null, "Thankyous 🙌"), "\n", React.createElement(_components.p, null, "Don't forget to say your thankyous, so that people want to keep riding with you!\nFor us, there'll be plenty of time for thankyous as we grow, but for now I wanted to say thank you to Amit at ", React.createElement(_components.a, {
    href: "https://www.tauventures.com/"
  }, "Tau Ventures"), ", for your kind advice, and for allowing us to meet Robert, who has helped to inspire a vision of a future of venture where equity can feel more like a hug, than a handshake **"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "** and definitely not like this"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/blog-images/wrestle.gif",
    alt: "arm-wrestle"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
